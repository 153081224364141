import React from "react";
import WorldMap from "react-svg-worldmap";

function Connections() {
  const data = [
    { country: "cn", value: 2 }, // china
    { country: "in", value: 2 }, // india
    { country: "us", value: 6 }, // united states
    { country: "ca", value: 1 }, // canada
    { country: "pk", value: 1 }, // pakistan
    { country: "bd", value: 500 }, // bangladesh
    { country: "ru", value: 1 }, // russia
    { country: "nl", value: 5 }, // netherlands
    { country: "de", value: 4 }, // germany
    { country: "be", value: 1 }, // belgium
    { country: "gb", value: 6 }, // uk
    { country: "my", value: 7 }, // malaysia
    { country: "au", value: 5 }, // australia
    { country: "sa", value: 20 }, // saudi
    { country: "bn", value: 3 }, // brunei
    { country: "qa", value: 1 }, // qatar
    { country: "kr", value: 2 }, // s.korea
    { country: "jp", value: 2 }, // japan
  ];

  return (
    <div className="connections text-center p-5">
      <WorldMap
        color="#f7f7f7"
        title="Connections I have around the globe"
        value-suffix="people"
        size="xl"
        data={data}
        richInteraction
        className="connections-card"
        borderColor="#ffffff"
        style={{ background: "#000000" }}
      />
    </div>
  );
}

export default Connections;
