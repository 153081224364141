/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import aiarterhome from "../../img/works/aiarterhome.png";
import pastelbeautyhome from "../../img/works/pastelbeautyhome.png";
import xtrachannelhome from "../../img/works/xtrachannelhome.png";
import kahfkids from "../../img/works/kahfkids.png";
import leafletshome from "../../img/works/leafletshome.png";
import sevenstarpd from "../../img/works/sevenstarpd.png";
import konstrathemeforest from "../../img/works/konstrathemeforest.png";
import fsecretshome from "../../img/works/fsecretshome.png";
import sevenstardashb from "../../img/works/sevenstardashb.png";
import jobaquahome from "../../img/works/jobaquahome.png";
import sevenstarapp from "../../img/works/sevenstarapp.png";
import track6app from "../../img/works/track6app.png";
import cpdlsupportapp from "../../img/works/cpdlsupportapp.png";
import finfolinkapp from "../../img/works/finfolinkapp.png";
import bikedotapp from "../../img/works/bikedotapp.png";
import anlyticai from "../../img/works/anlyticai.png";
import anlyticinbox from "../../img/works/anlyticinbox.png";

import React from "react";

function Works() {
  return (
    <div className="attention animations works">
      <div className="container">
        <div className="row align-items-start">
          <div className="mb-4">
            <h2 className="text-center text-light"> More works</h2>
            <p className="text-center text-light">
              Explore a curated selection of my latest design projects
            </p>
          </div>
          <div className="col-md-6 mb-3 works-column">
            <a
              href="https://drive.google.com/file/d/1FpaMFX1UadfxRywYYg7Hp61-2obkyHd-/view?usp=sharing"
              target="__blank"
            >
              <img
                src={aiarterhome}
                alt="aiarterhome"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>
          <div className="col-md-6 mb-3">
            <a
              href="https://drive.google.com/file/d/1Zq4VLqLD7yzZokosMDajh1l56v28VKZd/view?usp=sharing"
              target="__blank"
            >
              <img
                src={kahfkids}
                alt="kahfkids"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-6 mt-5 mb-3">
            <a
              href="https://drive.google.com/file/d/1O5BQijwRHv56A4f5_dbfQzpIV40MfRQr/view?usp=sharing"
              target="__blank"
            >
              <img
                src={pastelbeautyhome}
                alt="pastelbeautyhome"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>

          <div className="col-md-6 mt-5 mb-3">
            <a
              href="https://drive.google.com/file/d/100WiFL64qiYUlAtor1Qud1m9R3XMaSeO/view?usp=sharing"
              target="__blank"
            >
              <img
                src={anlyticai}
                alt="anlyticai"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>
          <div className="col-md-6 mt-5 mb-3">
            <a
              href="https://drive.google.com/file/d/15kXF6OWUOA53eUOxnn90lo5sZiAYwxOR/view?usp=sharing"
              target="__blank"
            >
              <img
                src={leafletshome}
                alt="leafletshome"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>
          <div className="col-md-6 mt-5 mb-5">
            <a
              href="https://drive.google.com/file/d/11Q4e5j1DK5md-Q0HYo6hNGHkV9kSE9Jw/view?usp=sharing"
              target="__blank"
            >
              <img
                src={jobaquahome}
                alt="jobaquahome"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>
        </div>

        {/*app ui starts*/}
        <div className="row justify-content-center mt-5">
          <div className="col-md-2 mb-3">
            <img
              src={sevenstarapp}
              alt="sevenstarapp"
              width={"100%"}
              className="shadow"
            />
          </div>

          <div className="col-md-2 mb-3">
            <img
              src={track6app}
              alt="track6app"
              width={"100%"}
              className="shadow"
            />
          </div>
          <div className="col-md-2 mb-3">
            <img
              src={cpdlsupportapp}
              alt="cpdlsupportapp"
              width={"100%"}
              className="shadow"
            />
          </div>
          <div className="col-md-2 mb-3">
            <img
              src={finfolinkapp}
              alt="finfolinkapp"
              width={"100%"}
              className="shadow"
            />
          </div>
          <div className="col-md-2 mb-3">
            <img
              src={bikedotapp}
              alt="bikedotapp"
              width={"100%"}
              className="shadow"
            />
          </div>
        </div>
        {/*app ui starts*/}

        <div className="row align-items-start mt-4">
          <div className="col-md-6 mt-5 mb-3">
            <a
              href="https://drive.google.com/file/d/1YGRsxPuTdwDh0QhRUg60GFjXE_p2kV-2/view?usp=sharing"
              target="__blank"
            >
              <img
                src={xtrachannelhome}
                alt="xtrachannelhome"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>
          <div className="col-md-6 mt-5 mb-3">
            <a
              href="https://drive.google.com/file/d/1JvnpdNqR9ZOrXEddG8CAG4lt8BiPZtfr/view?usp=sharing"
              target="__blank"
            >
              <img
                src={sevenstardashb}
                alt="sevenstardashb"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>

          <div className="col-md-6 mt-5 mb-3">
            <a
              href="https://drive.google.com/file/d/1CDUKR4g84ppmWxgpcg6xPvPHWDeWXree/view?usp=sharing"
              target="__blank"
            >
              <img
                src={sevenstarpd}
                alt="sevenstarpd"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>

          <div className="col-md-6 mt-5 mb-3">
            <a href="https://freelancingsecret.com/en/" target="__blank">
              <img
                src={fsecretshome}
                alt="fsecretshome"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>
          <div className="col-md-6 mt-5 mb-3">
            <a
              href="https://preview.themeforest.net/item/konstra-construction-template-for-architect-and-construction/full_screen_preview/23635565"
              target="__blank"
            >
              <img
                src={konstrathemeforest}
                alt="konstrathemeforest"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>
          <div className="col-md-6 mt-5 mb-3">
            <a
              href="https://drive.google.com/file/d/1zJanD1jWY74idL1NggylThrxbo5T3BVJ/view?usp=sharing"
              target="__blank"
            >
              <img
                src={anlyticinbox}
                alt="anlyticinbox"
                width={"100%"}
                className="shadow"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12 mt-5 text-center">
          <a href="/#works" className="btn btnCTA ramadan__project-btn">
            See case studies instead
          </a>
        </div>
      </div>
    </div>
  );
}

export default Works;
