/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import warningred from "../../../img/gif/warningred.gif";

import React from "react";
import Left from "./Left";
import Right from "./Right";

function Attention() {
  return (
    <div className="attention">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 align-self-center">
            <Left />
          </div>
          <div className="col-md-4 col-12">
            <div className="card attention-card text-center bg-transparent">
              <div className="card-img-top p-0 bg-transparent">
                <img src={warningred} alt="" />
              </div>
              <div className="card-body bg-transparent">
                <h2 className="card-title attention-one-middle-text">
                  ARE YOU READY?
                </h2>
                <p className="card-text">
                  You are entering into a diversified knowledgeable portfolio
                </p>
              </div>
              {/*<div className="card-footer">
              <a
                className="text-light"
                href="https://play.google.com/store/apps/details?id=com.sadaqahmadeeasy.app"
                target="__blank"
              >
                Get your Attention
              </a>
            </div>*/}
            </div>
          </div>
          <div className="col-md-4 align-self-end">
            <Right />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attention;
