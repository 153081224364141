/* eslint-disable jsx-a11y/anchor-is-valid */

// eslint-disable-next-line jsx-a11y/anchor-is-valid
import React from "react";
//import { CopyToClipboard } from "react-copy-to-clipboard";
import contactimage from "../../img/bg/contactimage.png";

function Contact() {
  const [value, setValue] = React.useState("hrahatsharif@gmail.com");
  const [copied, setCopied] = React.useState(false);
  const onChange = React.useCallback(({ target: { value } }) => {
    setValue(value);
    setCopied(true);
  }, []);
  const onClick = React.useCallback(({ target: { innerText } }) => {
    console.log(`Clicked on "${innerText}"!`);
  }, []);
  const onCopy = React.useCallback(() => {
    setCopied(true);
  }, []);
  return (
    <div className="contact bg-white">
      <div
        className="container"
        style={{ marginTop: "4rem", marginBottom: "0rem" }}
      >
        <div className="row ramadan__project-row align-items-start">
          <div className="col-md-9 col-12 mb-5">
            <h1
              className="text-dark fw-bold pt-5"
              style={{ letterSpacing: "0px" }}
            >
              Hey There! Did Someone <br /> Say🍦Ice-Cream? Let's Meet!
            </h1>
            <p className="text-dark" style={{ fontSize: "18px" }}>
              I always enjoy connecting with new people for a purpose, <br />{" "}
              enjoy learning things from every events.
              <br />
              <br />
              Don't waste your time finding a way to ping me! <br />
              Just Linkedin me.
            </p>

            <div className="d-flex flex-row">
              <a
                type="button"
                className="btn btnCTA ramadan__project-btn me-2"
                target="__blank"
                href="https://www.linkedin.com/in/hasansharifrht/"
              >
                Send a Linkedin DM
              </a>
            </div>
          </div>
          {/*<div className="col-md-3 col-12">
            <img src={contactimage} className="rounded" width={"100%"} />{" "}
          </div>*/}
          <div className="col-md-3 col-12 mb-5">
            {" "}
            <div className="d-flex flex-column">
              <img
                src={contactimage}
                className="rounded mb-4 bg-white p-2 shadow-sm"
                width={"100%"}
              />{" "}
              <section className="adplist-section mb-4">
                <iframe
                  src="https://adplist.org/widgets/impact?src=hasan-sharif"
                  title="Impact"
                  width="100%"
                  height="100%"
                  loading="lazy"
                  style={{
                    border: "1px",
                    borderRadius: "10px",
                    background: "#000000",
                  }}
                ></iframe>
              </section>
              <div className="card">
                <div className="table table-danger">
                  <h5 className="card-caption text-dark">Available for</h5>
                  <div className="card-description">
                    <p className="text-dark fw-normal">
                      {" "}
                      ✔ Senior UX / Product / UI Design role
                    </p>
                    <p className="text-dark fw-normal"> ✔ UX Research Role</p>
                    <p className="text-dark fw-normal"> ✔ Ice Cream</p>
                    <p className="text-dark fw-normal"> ✔ Mentoring</p>
                    <p className="text-dark fw-normal"> ✔ Events</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="table table-danger">
                  <h5 className="card-caption text-danger">
                    Not interested in
                  </h5>
                  <div className="card-description">
                    <p className="text-dark fw-normal"> ✗ Biriyani</p>
                    <p className="text-dark fw-normal"> ✗ Clickbait events</p>
                    <p className="text-dark fw-normal"> ✗ Speculation</p>
                    {/*<p> ✗ lcohol</p>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<iframe
        src="https://gifer.com/embed/DO5"
        width="480"
        height="480.000"
        allowFullScreen
      ></iframe>*/}
    </div>
  );
}

export default Contact;
