/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React from "react";

import shopify from "../img/logos/shopify.svg";
//import githublogo from "../img/logos/github-logo.png";
import wordpresslogo from "../img/logos/wordpresslogo.svg";
import materiallogo from "../img/logos/material-logo.png";

import { useLocation } from "react-router-dom";

function More() {
  return (
    <div className="container-fluid more" id="more">
      {/*more */}
      <div className="row mt-5">
        <div className="col-md-4 col-12">
          <div className="glowable-text">
            <div className="card card-1 mb-3">
              <div className="card-body text-light">
                <p className="text-dark glowable-text">
                  ↳ Boosted design efficiency by ~71% using AI
                </p>
                <p className="text-dark glowable-text">
                  ↳ Researching user needs for 2 years
                </p>
                <p className="text-dark glowable-text">
                  ↳ Played with Frontend{" "}
                  <i className="fa fa-code" aria-hidden="true"></i> for 2 years
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="glowable-text">
            <div className="card card-3 mb-3">
              <div className="card-body">
                <p className="card-3-text">
                  ↳ Crafting User-Centered experiences for <b>6 years</b>
                </p>
                <p className="card-3-text">
                  ↳ UI Designing with principles for 2 years
                </p>
                <p className="card-3-text">
                  <span className="d-flex flex-row align-items-center pt-2">
                    ↳ Design Systems I love{" "}
                    <a href="https://automattic.design/" target="__blank">
                      <img
                        src={wordpresslogo}
                        alt=""
                        width="30px"
                        height="30px"
                        className="ms-2 me-2"
                      />
                    </a>
                    <a
                      href="https://www.figma.com/@materialdesign"
                      target="__blank"
                    >
                      <img
                        src={materiallogo}
                        alt=""
                        width="30px"
                        height="30px"
                        className="ms-2 me-2"
                      />
                    </a>
                    <a href="https://polaris.shopify.com/" target="__blank">
                      <img
                        src={shopify}
                        alt=""
                        width="30px"
                        height="30px"
                        className="ms-2 me-2"
                      />
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="glowable-text">
            <div className="card card-1 mb-3">
              <div className="card-body">
                <p className="text-dark glowable-text">
                  ↳ UX Instructor at{" "}
                  <a
                    href="https://edge.gov.bd/"
                    target="__blank"
                    className="text-dark text-decoration-underline"
                  >
                    EDGE Bangladesh
                  </a>
                </p>
                <p className="text-dark glowable-text">
                  ↳ Mentor at{" "}
                  <a
                    href="https://adplist.org/mentors/hasan-sharif"
                    target="__blank"
                    className="text-dark text-decoration-underline"
                  >
                    ADPList
                  </a>
                </p>
                <p className="text-dark glowable-text">
                  ↳ Customer Researcher at{" "}
                  <a
                    href="https://www.organingo.com"
                    target="__blank"
                    className="text-dark text-decoration-underline"
                  >
                    Organingo
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*more */}

      {/*<div className="row"></div>*/}
    </div>
  );
}

export default More;
