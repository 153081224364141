/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid
// eslint-disable-next-line jsx-a11y/img-redundant-alt
import React from "react";
import anlytic from "../../img/reviews/anlytic.svg";
import mhservices from "../../img/reviews/mhservices.svg";
import martijn from "../../img/reviews/martijn.png";
import mozammel from "../../img/reviews/mozammel.png";

import { useLocation } from "react-router-dom";

function Reviews() {
  const location = useLocation();

  //  const { searchProjects, handleSearch } = this.context;

  return (
    <div className="container-fluid reviews">
      <div className="row mt-5">
        <div className="col-md-6 col-12">
          <div className="card border-dark mb-3">
            <img className="card-img-top" src={anlytic} alt="Card image cap" />
            <div className="card-body text-light">
              <p className="card-text">
                Hasan demonstrated not only expertise in UX design. He was
                incredibly dedicated to Anlytic, showing genuine loyalty to the
                company and striving for improvements. He constantly contributed
                ideas to enhance our product, inspire the team, and support
                Anlytic’s growth. When Hasan stepped into a team lead role,
                where he mentored other designers, and proposed loads of
                initiatives, Hasan organized the work environment in a clear way
                and kept improving it.
              </p>

              <div className="review-card-person">
                <div className="review-card-person-info">
                  <img
                    className="person-img"
                    src={martijn}
                    alt="Card image cap"
                  />
                  <div className="review-card-person-text-group">
                    <p className="card-text-bottom text-dark">
                      Martijn Witteveen
                    </p>
                    <p className="card-subt-text-bottom">CEO at Anlytic.com</p>
                  </div>
                </div>
                <div className="review-link ms-2">
                  <a
                    href="https://drive.google.com/file/d/19oQd96l1M8BcoawlVVDbsv_57JA4Nzjp/view?usp=sharing"
                    target="__blank"
                  >
                    <i
                      className="fa fa-sm fa-external-link"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="card border-dark">
            <img
              className="card-img-top"
              src={mhservices}
              alt="Card image cap"
            />
            <div className="card-body text-light">
              <p className="card-text">
                It was a great pleasure working with Hasan Sharif. He has a keen
                eye for design, and I would describe him as knowledgeable, eager
                to learn, and a team player. Working with him has been a
                valuable learning experience for me. He brings a rich blend of
                theoretical and practical knowledge, which is evident in his
                designs. He would be a great asset to any team looking for an
                expert UX designer. I wish him all the best in his future
                endeavors.
              </p>

              <div className="review-card-person">
                <div className="review-card-person-info">
                  <img
                    className="person-img"
                    src={mozammel}
                    alt="Card image cap"
                  />
                  <div className="review-card-person-text-group">
                    <p className="card-text-bottom text-dark">
                      Mozammel Hossain
                    </p>
                    <p className="card-subt-text-bottom">
                      Founder, MH Services
                    </p>
                  </div>
                </div>
                <div className="review-link ms-2">
                  <a
                    href="https://www.linkedin.com/in/hasansharifrht/details/recommendations/?detailScreenTabIndex=2"
                    target="__blank"
                  >
                    <i
                      className="fa fa-sm fa-external-link"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
