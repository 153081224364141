//import bdotmap from "../../vdo/bdotmap.mp4";

import machcover from "../../img/mach/machcover.jpg";
import machintroimg from "../../img/mach/machintroimg.jpeg";
import machecomcovid from "../../img/mach/machecomcovid.webp";
import machdiamond from "../../img/mach/machdiamond.webp";
import machsupplyflow from "../../img/mach/machsupplyflow.webp";
import machcompaudit from "../../img/mach/machcompaudit.webp";
import machuserflow from "../../img/mach/machuserflow.webp";
import machuserscenario from "../../img/mach/machuserscenario.webp";
import machpersonas from "../../img/mach/machpersonas.webp";
import machcardsort from "../../img/mach/machcardsort.webp";
import machsitemap from "../../img/mach/machsitemap.webp";
import machwireframe from "../../img/mach/machwireframe.webp";
import machsketch from "../../img/mach/machsketch.webp";
import machpdp from "../../img/mach/machpdp.webp";
import machuihome1 from "../../img/mach/machuihome-1.webp";
import machuihome2 from "../../img/mach/machuihome-2.webp";
import machuihome3 from "../../img/mach/machuihome-3.webp";
import machuipdp from "../../img/mach/machuipdp.webp";
import machuicart from "../../img/mach/machuicart.webp";
import machuicheckout from "../../img/mach/machuicheckout.webp";
import machpdpfinal from "../../img/mach/machpdpfinal.webp";
import machmobilebanking from "../../img/mach/machmobilebanking.webp";
import machendowment from "../../img/mach/machendowment.webp";
import machmanualpayflow from "../../img/mach/machmanualpayflow.webp";

//last serial: 1.5

const ProjectFifthData = [
  {
    _ID: "sdqme01.5",
    projectImg: machcover,
    //projectVdo: bdotcoverVdo,
    projectHeading:
      "Machbazaar - An E-commerce store to sell fresh fish to consumers in wholsale and retail system",
    projectSubTitle: (
      <>
        Machbazaar is a dedicated E-commerce / Online Store where both B2C and
        B2B model are being followed as its business model. Consumers can
        purchase fish for their family or for their business. I designed the web
        app for them and submitted competitive audit report to the business
        functional team which has been used in shaping their sales funnel.
      </>
    ),
    slug: "machbazaar",
    based: "Bangladesh",
    tag: "E-commerce",
    timeline: <span> 09/2020 - 12/2020</span>,
    projectDetails: (
      <div className="row">
        {/*intro */}
        <div className="col-md-12 mb-5">
          <h3>
            Machbazaar is a dedicated E-commerce / Online Store where both B2C
            and B2B model are being followed as its business model. Consumers
            can purchase fish for their family or for their business. I designed
            the web app for them and submitted competitive audit report to the
            business functional team which has been used in shaping their sales
            funnel.
          </h3>
          {/*<p
            className="text-secondary text-center fw-light mt-5 ps-2 pe-2"
            style={{ fontSize: "22px" }}
          >
            <i>
              To comply with my confidentiality agreement I have omitted and
              appropriated confidential information. These documentation and designs are the
              reinterpretation of the original.
            </i>
          </p>*/}
        </div>
        <div className="col-md-6 col-12 mt-4">
          <h4>
            <u>Problem statement</u>
          </h4>
          <p>
            Fresh and unadulterated fish are very rare now-a-days. Although
            fishermen are trying their best to supply the raw state of fishes
            which crosses minimum of four stages in supply chain, but the end
            consumers gets fresh-state fish very hardly. Thus zoonotic diseases
            associated with fish has been detected due to transmittion of
            bacterial infections from fish to human being. Machbazaar wants to
            solve this existing problem launching an E-commerce / Online Store
            of fresh fish.
          </p>
        </div>
        <div className="col-md-6 col-12 mt-4">
          <h4>
            <u>My role</u>
          </h4>
          <ul>
            <li>User research</li>
            <li>Competitive analysis</li>
            <li>Sketch</li>
            <li>Low-fidelity wireframe</li>
            <li>UI design</li>
          </ul>
        </div>

        {/*intro screens */}
        <div className="col-md-12">
          <img src={machintroimg} alt="bdot-intro-img" className="w-100" />
          <p
            className="text-center text-secondary mt-3"
            style={{ fontSize: "14px" }}
          >
            MACHBAZAAR web screens
          </p>
        </div>

        {/*last insight */}
        <div className="row" style={{ marginTop: "2rem" }}>
          {/*The challenge */}
          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
            <h3 className="mb-4 fw-bold">The challenge</h3>
            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
              The client came to me with two main objectives: (i) perform a
              feasible research as well as competitive audit based on realistic
              data to identify opportunities and find an edge and (ii) captivate
              user's attraction with proper reflection of emotion in the end
              design.
            </p>
          </div>
          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
            <h3 className="mb-4 fw-bold">Timeline</h3>
            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
              I had 3 months to submit the final UI. I had to submit the
              competitive audit report within the first month and attend meeting
              with client and stakeholders to gather feedback. In the second
              month, I visited couple of fish suppliers stores and took
              interview from target audience. And the last month is for the
              visual design submission.
            </p>
          </div>
          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
            <h3 className="mb-4 fw-bold">With stakeholders</h3>
            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
              The main challenge in the phase of syncing with stakeholders was
              performing competitive audit with realistic data. Business
              development managers were proactive in assisting my audit. At the
              last stage before submitting UI files, meeting with developers
              were instructive too in case of understanding design feasibility.
            </p>
          </div>
        </div>

        {/*E-com shift in covid */}
        <div className="row" style={{ marginTop: "2rem" }}>
          <div className="col-md-6">
            <p className="fw-light">Some background works &nbsp;⎯⎯</p>
            <p>
              {" "}
              <b>E-commerce consumers acceleration in COVID-19</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              Research shows that the value of transactions in four key areas —
              E-commerce, Travel, Media and Transport and food — should grow
              just $5 billion to about $105 billion in 2020, when many consumers
              turned to mobile shopping for the first time. A March 2021 news
              article reports that more than 17,500 chain store outlets closed
              in 2020, with COVID spurring an “average daily closure of 48
              shops, restaurants and other leisure and hospitality venues.”
            </h3>
          </div>
          <div className="col-md-6" style={{ marginTop: "2rem" }}>
            <img
              src={machecomcovid}
              alt="mach-ecom-covid"
              className="w-100 mt-5"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              COVID-19 sparks Southeast Asian e-commerce trend - Copyright
              Aljazeera & Bloomberg
            </p>
          </div>
          <div className="col-md-12" style={{ marginTop: "2rem" }}>
            <h3 style={{ lineHeight: "35px" }}>
              This led to a shift in consumer behavior toward online buying,
              which required them to focus all of their efforts on e-commerce
              and online presence in order to maintain a profit and cut losses.
              Many businesses and brands made investments to enhance their
              customers' online experiences.
            </h3>
          </div>
        </div>

        {/*design framework */}
        <div className="row" style={{ marginTop: "10rem" }}>
          <div className="col-md-6">
            <p>
              {" "}
              <b>Method used</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              As the problem needs to be solved was explicitely involved with
              people's heath, a people centered process was selected. "Double
              Diamond" framework enabled the whole process to communicate
              visually and inclusively with the client and others.
              <br />
              <br />
              Understanding how people connect themselves and their health care
              with the technology was the major part to be discovered before
              jumping to a straigth solution. And at the "Discover" phase of
              "Double Diamond" framework played a significant role in getting a
              clear picture of the mentioned problem.
            </h3>
          </div>
          <div className="col-md-6">
            <img
              src={machdiamond}
              alt="mach-double-diamond"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Double Diamond Process helped me to gain a shared understanding of
              the problem and ideas. (Img by Design Council)
            </p>
          </div>
        </div>

        {/*Proces - discover - understand people*/}
        <div className="row" style={{ marginTop: "10rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Discover - Understand people first</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              Rather than forming assumptions of what the problem is, I spent
              time talking with consumers and suppliers of the fish industry. To
              deep dive into fishermen's lifestory, I reached out to couple of
              main sources of fish supply in Chittagong and Khulna.
            </h3>
          </div>{" "}
          <div className="col-md-12 mt-5">
            <img
              src={machsupplyflow}
              alt="mach-double-diamond"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              Upstream/downstream flow of supply chain of Sea fish in Bangladesh
            </p>
          </div>
          <div className="col-md-12 text-center" style={{ marginTop: "2rem" }}>
            <h3 style={{ lineHeight: "35px" }}>
              After thorough investigation, I constructed a basic
              upstream/downstream flow of supply chain of Sea fish taking help
              from authentic resources (from source to consumer with
              indication).
            </h3>
          </div>
        </div>

        {/*Proces - discover - persona*/}
        <div className="row" style={{ marginTop: "5rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Met with diverse sets of consumers</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              As Machbazaar follows B2B and B2C model as their business model, I
              visited three levels of suppliers in the industry as well as the
              end consumers who purhcase in retail process. Machbazaar's service
              is for suppliers of "Distribution Level" and "Sale Level" that is
              consumer.
            </h3>
          </div>{" "}
          <div className="col-md-12 mt-5">
            <img
              src={machuserscenario}
              alt="mach-user-scenario"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              Images of suppliers in the fish industry of Bangladesh
            </p>
          </div>
          <div className="col-md-12" style={{ marginTop: "5rem" }}>
            <p>
              {" "}
              <b>Demonstrating user persona</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              Dhaka - Chittagong are two of the cities where most fish supply
              and purchase takes place. Roaming around these cities with some
              acquaintances who are the regular fish consumers and target
              audience of B2C, I tried to gather their pain points based on
              current fish E-commerce ecosystem. Next, I visited B2B target
              audiences, that is, whole salers of fish in local market where I
              noticed some significant use cases that can be implemented in
              Machbazaar.
            </h3>
            <img src={machpersonas} alt="mach-persona" className="w-100 mt-4" />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              User personas of two level of users / customers : Direct consumer
              & Whole saler
            </p>
          </div>
        </div>

        {/*Proces - discover - comp audit*/}
        <div className="row" style={{ marginTop: "8rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Identify opportunities and find an edge</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              In the phase of discovering what products or services the target
              customers are currently using to solve the same{" "}
              <a href="#top">
                {" "}
                <u> problem</u>
              </a>
              , three direct and two indirect prominent competitors was enlisted
              into the audit sheet.
              <br />
              <br />
              As "Product details "or, "Product page" is the{" "}
              <b className="fw-bold">
                centerpiece of user's e-commerce experience,
              </b>{" "}
              I have discovered WHAT, WHY, HOW of each competitor's product
              details page to get the best out of this audit (opportunities &
              edge). For confidential issue, I have concealed the names of
              direct competitor's brands.
            </h3>
          </div>{" "}
          <div className="col-md-12 mt-5">
            <img
              src={machcompaudit}
              alt="mach-double-diamond"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              Competitor audit sheet
            </p>
          </div>
          <h3
            style={{
              marginTop: "7rem",
              lineHeight: "45px",
              textAlign: "center",
            }}
          >
            ❝~60% e-commerce users see product page as an entry point but only
            18% of the site has an overall acceptable or good product page UX
            performance - Baymard Institute❞
          </h3>
        </div>

        {/*synthesize comp audit */}
        <div className="row" style={{ marginTop: "7rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Define - Let's present opportunities</b>
            </p>
            <h3 style={{ lineHeight: "40px", marginBottom: "3rem" }}>
              Research states that product details page is the mean by which 60%
              of users first accesses an E-commerce website by organic clicks.
              Only 18% of the E-commerce website has an overall acceptable or
              good product page UX performance. Most importantly, almost all
              users visits the product page before making any purchase through a
              E-commerce site. Based on the competitive audit and primary user
              research demonstrated earlier, I have amalgamated some{" "}
              <b className="fw-bold">potential opportunities</b> mentioned
              below:
            </h3>
          </div>
        </div>
        <div
          className="row justify-content-center"
          style={{ marginTop: "1rem" }}
        >
          <div className="col-md-6 shadow-sm border">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                1. Leverage the product page with consumers emotion in
                purchasing product from online
              </li>
              <li class="list-group-item">
                2. Use the same layout that most of the online users are used to{" "}
              </li>
              <li class="list-group-item">
                3. Keep the functionality B2B and B2C friendly to enable
                wholesale and retails customer purchase fish with customized
                quantity
              </li>
            </ul>
          </div>
        </div>

        {/*card sort*/}
        <div className="row" style={{ marginTop: "5rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Understanding user's mental model - Road to Sitemap</b>
            </p>
            <h3 style={{ lineHeight: "40px" }}>
              Due to its flexibility, I along with some invited participants
              online (based on target audience), conducted a card sorting
              session digitally where topics were typed onto cards in a board.
              It enabled me and the business team to create intuitive user
              experiences and customer experience by organizing and categorizing
              content the way target customers do in their minds. Here I
              attached the final organization of this session.
            </h3>
          </div>{" "}
          <div className="col-md-12 mt-5">
            <img src={machcardsort} alt="mach-card-sort" className="w-100" />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              Card sorting session conducted in Optimal Sort
            </p>
          </div>
        </div>

        {/*common sitemap*/}
        <div className="row" style={{ marginTop: "8rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Structure of the site - Sitemap</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              For understanding the hierarchy of Machbazaar, a sitemap needed to
              be presented to the client which will be useful to make the
              sitemap.xml file that is important making the site SEO friendly at
              production level.
            </h3>
          </div>
          <div className="col-md-12">
            <img src={machsitemap} alt="mach-sitemap" className="w-100" />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "2rem" }}
            >
              Sitemap curated from card sorting arrangements
            </p>
          </div>
        </div>

        {/*common user flow*/}
        <div className="row" style={{ marginTop: "8rem" }}>
          <div className="col-md-5">
            <p>
              {" "}
              <b>Let's not reinvent the wheel - User Flow</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              To enhance familiarity, reduce learning path and cognitive load, I
              decided not to override the established user flow of an E-commerce
              website.
            </h3>
            <h4>
              {" "}
              However, in the development phase of ideation, I have presented
              couple of layouts which will communicate with the users more
              proficiently.
            </h4>
          </div>
          <div className="col-md-7">
            <img
              src={machuserflow}
              alt="mach-double-diamond"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Industry standard user flow using for Machbazaar E-commerce
            </p>
          </div>
        </div>

        {/*develop - sketch*/}
        <div className="row" style={{ marginTop: "8rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Develop - Build the big picture by Sketching</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              Instead of creating a huge scheme, I tried generating layouts and
              organizing the ideas around the core problem. Sketches boosted the
              progress allowing comprehensive communication with both the team
              and subject matter experts.
            </h3>
          </div>
          <div className="col-md-12">
            <img src={machsketch} alt="mach-sitemap" className="w-100" />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Sketch helped shape the visualization of the user flow
            </p>
          </div>
        </div>

        {/*develop - wireframe*/}
        <div className="row" style={{ marginTop: "8rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Skeleton being ready</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              After sketching, I made the primary wireframe of the end visual
              with mid-fidelity elements which has been presented to the client
              for gathering feedback. Showing this wirefame, the team got a
              page-level layout idea that depicts functionality, behavior, and
              hierarchy of content.
            </h3>
          </div>
          <div className="col-md-12">
            <img src={machwireframe} alt="mach-sitemap" className="w-100" />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "2rem" }}
            >
              Wireframe presented to the team
            </p>
          </div>
        </div>

        {/*pdp demonstration*/}
        <div className="row" style={{ marginTop: "8rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>
                How I leveraged the product page with consumers emotion in
                purchasing product from online - Synthesized from Competitor
                audit
              </b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              From the image below, it can easily understood that I have added
              two semi-size card to present "Benefits of the fish a consumer
              wants to buy" and "How the user (visiting that product page) can
              learn something new about identifying adulterated fish".{" "}
              <a href="https://behaviormodel.org/" target="__blank">
                {" "}
                Fogg Behavior Model (FBM){" "}
              </a>
              helped me to design this experience from where Machbazaar can
              increase their conversion rate.
            </h3>
            <h4 className="mt-4" style={{ lineHeight: "35px" }}>
              {" "}
              After showing this demonstration of product page of Machbazaar to
              the client, I got 'CONGRATULATIONS, JOB WELL DONE' remarks from
              the team which inspired me very well to lead the experience with
              more enthusiasm than before.
            </h4>
          </div>
          {/*Final ui of pdp*/}
          <div className="col-md-12 mt-5">
            <img src={machpdp} alt="mach-double-diamond" className="w-100" />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              The FBM model thoroughly boosted me to design the Machbazaar
              product detail page experience
            </p>
          </div>
          <div className="col-md-12 mt-5">
            <h4 className="text-center">Final UI of Product Details page</h4>
            <img src={machpdpfinal} alt="mach-pdp-final" className="w-100" />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "-5rem" }}
            >
              Look and feel of PDP of Machbazaar
            </p>
          </div>
          {/*endowment effect */}
          <div className="row align-items-center" style={{ marginTop: "8rem" }}>
            <div className="col-md-5">
              <p>Enhanced psychology</p>
              <h4 style={{ lineHeight: "40px" }}>
                To make a potential user feel that the fish it's almost his/her,
                the element "Get it today" is designed inspired by the idea{" "}
                <b className="fw-bold">The Endowment Effect </b> which increases
                adoption and retention of use.
              </h4>
            </div>
            <div className="col-md-7">
              <img src={machendowment} alt="mach-endowment" className="w-100" />
              <p
                className="text-center text-secondary mt-5"
                style={{ fontSize: "14px" }}
              >
                Endowment effect applied in an element of PDP
              </p>
            </div>
          </div>
        </div>

        {/*Manual payment*/}
        <div className="row" style={{ marginTop: "8rem" }}>
          <div className="col-md-5">
            <p>
              {" "}
              <b>The manual payment process - Trade-off</b>
            </p>
            <h4 style={{ lineHeight: "35px" }}>
              Because of a business trade-off, instead of integrating online
              payment system, the payment procedure was kept manual. For that
              reason, I had to make a simple and intuitive experience of manual
              payment where proper payment information (Payment details) was
              displayed in the checkout page. "Send Money" is the most commonly
              used in the mobile banking use cases which is equipped with the
              checkout page. While sending payment, user just checks the account
              number beside the mobile banking operator's name (Bkash / Nogod /
              Rocket) .
            </h4>
          </div>

          <div className="col-md-7">
            <img
              src={machmobilebanking}
              alt="mach-payment-process"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Mobile banking made the online payment procedure easier now-a-days
            </p>
          </div>
          <div className="col-md-12" style={{ marginTop: "5rem" }}>
            <h4
              style={{
                marginTop: "3rem",
                marginBottom: "5rem",
                textAlign: "center",
              }}
            >
              Demonstration of admin user flow from receiving money manually to
              confirm order
            </h4>
            <img
              src={machmanualpayflow}
              alt="mach-payment-process-flow"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Admin end user flow of confirmation of manual payment
            </p>
            <h4
              style={{
                lineHeight: "35px",
                textAlign: "center",
                marginTop: "3rem",
              }}
            >
              Due to digital banking ecosystem got adopted within the target
              audience very well, manual payment system isn't going to be
              creating any pitfall to the business rather users will be
              benefitted from the knowledge they gained using mobile banking
              technology.
            </h4>
          </div>
        </div>

        {/*Deliver - UI showcase*/}
        <div className="row text-center" style={{ marginTop: "12rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Deliver - Presenting UI (Mockup)</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              As the end design was based in Bangladesh (Language - Bangla), I
              am now presenting the visual design at high-fidelity level with
              Mockup as it is designed using both English and Bangla language.
              Most of the consumers of B2C are highly educated who prefers
              English and Bangla language both. And some fishes names were
              needed to be displayed in Bangla to avoid difficulty in
              pronunciation.
            </h3>
          </div>

          {/*Home page -1 */}
          <div className="col-md-12 mt-5">
            <img src={machuihome1} alt="mach-home-page-1" className="w-100" />
          </div>

          {/*Home page -2 */}
          <div className="col-md-12 mt-5">
            <h3
              style={{
                lineHeight: "35px",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
            >
              As our target customer's spectrum includes fish suppliers of
              distribution level, I have added a dedicated section in the home
              page from where consumers of B2B can find "Bulk Order" product
              cards to purchase fish for their business in customized quantity.
            </h3>
            <img
              src={machuihome2}
              alt="mach-home-page-2"
              className="w-100"
              style={{
                marginBottom: "5rem",
              }}
            />
          </div>

          {/*Home page -3 */}
          <div className="col-md-12 mt-5">
            <h3
              style={{
                lineHeight: "35px",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
            >
              A featured section with a video linked up within Home page which
              has a short video of a fisherman share his story of fishing life.
              This sections conveys a connection between a layman and a
              fisherman.
            </h3>
            <img src={machuihome3} alt="mach-home-page-3" className="w-100" />
          </div>

          {/*product details page */}
          <div className="col-md-12 mt-5">
            <h3
              style={{
                lineHeight: "35px",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
            >
              Product details page has gained distinct priority in case of
              designing in order to make the best outcome of using Fogg Behavior
              Model.
            </h3>
            <img src={machuipdp} alt="mach-product-details" className="w-100" />
          </div>

          {/*cart page */}
          <div className="col-md-12 mt-5">
            <h3
              style={{
                lineHeight: "35px",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
            >
              Cart page was connected with a stepper element to let user keep
              track of the progress of purchasing fish. A steppers have clear,
              explicit signifiers which carries valuable design intuition. To
              pull up Drop-off rate in the cart-checkout moment, a stepper is
              highly helpful also. It has a clear CTA button too.
            </h3>
            <img src={machuicart} alt="mach-cart" className="w-100" />
          </div>

          {/*checkout page */}
          <div className="col-md-12 mt-5">
            <h3
              style={{
                lineHeight: "35px",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
            >
              After presenting low-fidelty wirefame of the checkout page, client
              wanted to add a "Track Order" option so that after the
              confirmation of checkout, users can get an update about the
              order's progress. This feedback was really beneficial to me
              because by iterating this requirement, my time was saved a lot. My
              plan was to gather feedback based on the checkout and propose this
              new feature of tracking order to the client. However, the stepper
              used in the cart page, can be noticable.
            </h3>
            <img src={machuicheckout} alt="mach-checkout" className="w-100" />
          </div>
        </div>

        {/*Last summary */}
        <div
          className="row border rounded p-5 shadow-sm"
          style={{ marginTop: "2rem" }}
        >
          <div className="col-md-12">
            <h3 style={{ lineHeight: "45px" }}>
              Machbazaar is now planning to develop an native app. In the
              village region, there are lots of fisherman whose dream is to
              reach wordwide by providing fresh fish which is being nurtured by
              their hands. In that case, an app would be very usable to them.
            </h3>
          </div>
        </div>

        {/*The impact and lesson learnt */}
        <div
          className="d-flex justify-content-center p-3"
          style={{ marginTop: "5rem" }}
        >
          <div className="flex-div border rounded bg-light shadow-sm p-3 me-3">
            <h2 className="text-center mb-4">
              <u>The impact</u>
            </h2>
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                fontSize: "28px",
                lineHeight: "45px",
              }}
            >
              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Machbazaar has launched three outlets in the capital of
                Bangladesh, Dhaka
              </li>

              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Trust of last level of suppliers (B2B purchase) has been gained
                significantly within last 1 year
              </li>
              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i> User
                Group of active delivery person has joined in the deliver system
                of Machbazaar.
              </li>
            </ul>
          </div>

          <div className="flex-div border rounded shadow-sm p-3">
            <h2 className="text-center mb-4">
              <u>Lesson learnt</u>
            </h2>
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                fontSize: "28px",
                lineHeight: "45px",
              }}
            >
              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                Quality of tangible products should be well expressed in the
                digital product design
              </li>

              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                Shopping experience have to be enjoyable and concerned of
                drop-off rate.
              </li>
              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                Engaging product presentation is an effective way of increasing
                conversion.
              </li>
            </ul>
          </div>
        </div>

        <p className="text-center" style={{ marginTop: "5rem" }}>
          - A big thank you for the time you spent reading this case study -
        </p>
      </div>
    ),
  },
];

export function getProjectFifthData() {
  return ProjectFifthData;
}
