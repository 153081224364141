import React from "react";

function FixedFooter() {
  return (
    <footer className="fixed-footer">
      Copyright © 2025 - All rights reserved - Site made by{" "}
      <a
        className="text-white"
        href="https://www.linkedin.com/in/hasansharifrht/"
        target="__blank"
      >
        Hasan Sharif
      </a>
    </footer>
  );
}

export default FixedFooter;
