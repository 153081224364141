import { Parallax } from "react-scroll-parallax";

function Right() {
  return (
    <>
      <Parallax speed={10}>
        <div className="slow attention-one-right">
          <div className="card text-center mt-3 p-0">
            <div className="card-body">
              <h2 className="card-title">
                <i className="fa fa-question-circle" aria-hidden="true"></i> UX
                StackExchange
                <span className="card-date"> by StackOverFlow</span>
              </h2>
              <p>
                <a
                  href="https://ux.stackexchange.com/questions/143895/what-are-some-rationales-for-requiring-end-users-to-create-an-account-when-they/143897#143897"
                  target="__blank"
                  className="card-text fw-light"
                >
                  “There are two basic rationales work behind this 'force to
                  sign up...
                </a>
              </p>
              <p>
                <a
                  href="https://ux.stackexchange.com/questions/149474/good-alternative-for-popups-that-show-events/149475#149475"
                  target="__blank"
                  className="card-text fw-light"
                >
                  “To promote an event, products have been boosting their
                  community-based features...
                </a>
              </p>
              <p>
                <a
                  href="https://ux.stackexchange.com/questions/148536/table-input-with-modifier/149914#149914"
                  target="__blank"
                  className="card-text fw-light"
                >
                  “For this kind of complex table where formulas need to be
                  applied, the sidebar can be a good enhancement...
                </a>
              </p>
            </div>
            {/*<div className="card-footer">
              <a
                className="text-light"
                href="https://play.google.com/store/apps/details?id=com.sadaqahmadeeasy.app"
                target="__blank"
              >
                Read now
              </a>
            </div>*/}
          </div>
        </div>
      </Parallax>
    </>
  );
}
export default Right;
