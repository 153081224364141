import React from "react";

export default function Banner() {
  return (
    <div className="containter bg-banner">
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="banner-text">
            <h3>Designed a BI platform with</h3>
            <div class="white-background"></div>
            <span class="large-text">25%</span>{" "}
            <span class="small-text">cost savings</span>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="banner-text">
            <h3>Impacted my user research</h3>
            <div class="white-background"></div>
            <span class="large-text">183 </span>{" "}
            <span class="small-text">people / day</span>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="banner-text">
            <h3>Redesigned checkout flow</h3>
            <div class="white-background"></div>
            <span class="large-text">20%</span>{" "}
            <span class="small-text">conversion ↑</span>
          </div>
        </div>
      </div>
    </div>
  );
}
