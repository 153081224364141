/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

//import warningred from "../../img/gif/warningred.gif";

import React from "react";
import ProtoLeft from "./ProtoLeft";
import ProtoRight from "./ProtoRight";
//import MiddleOne from "./MiddleOne";
//import MiddleTwo from "./MiddleTwo";

import cosmeticproto from "../../../img/gif/cosmeticproto.gif";
import finshareproto from "../../../img/gif/finshareproto.gif";
import { auto } from "@popperjs/core";

function Animations() {
  return (
    <div className="attention animations">
      <div className="container">
        <div className="row align-items-center">
          <div className="mb-4">
            <h2 className="text-center text-light"> Me As a Protominator </h2>
            <p className="text-center text-light">Prototype + Animator</p>
            <p className="text-center text-muted">
              Use desktop view for best experience
            </p>
          </div>
          <div className="col-md-6">
            <ProtoLeft />
          </div>
          <div className="col-md-6">
            <ProtoRight />
          </div>
        </div>

        <div className="row ">
          <div className="col-md-8 mt-5">
            {" "}
            <img src={cosmeticproto} alt="" />
          </div>
          <div className="col-md-4 mt-5">
            {" "}
            <img src={finshareproto} height={"350px"} alt="" />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12 mt-5">
            <h5 className="text-muted text-center">More to come</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Animations;
