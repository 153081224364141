/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

//import FancyText from "@carefully-coded/react-text-gradient";
import React, { useState } from "react";
import { useTrail, animated } from "@react-spring/web";
import ixdfbadge from "../img/badge/ixdfbadge.svg";
//import playstorebadge from "../img/badge/playstorebadge.svg";
import courserabadge from "../img/badge/courserabadge.svg";
import stackexchange from "../img/badge/stackexchange.svg";
import adplist from "../img/badge/adplist.svg";
import { useLocation, Link } from "react-router-dom";

function Header() {
  const location = useLocation();

  const [open, setOpen] = useState(true);
  const items = [" I understand the problem first", "then design the solution"];

  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? 55 : 0,
    from: { opacity: 0, x: 20, height: 0 },
    delay: 200, // 2 second delay
    config: { tension: 280, friction: 60 },
  });

  return (
    <div className="headerBg">
      <div className="header container-fluid">
        <div className="row ramadan__project-row align-items-center rounded">
          <div className="col-md-12 col-12">
            <div className="header__flexOne text-center">
              <div className="header__content">
                {trail.map((props, index) => (
                  <animated.div key={index} style={props}>
                    <h1>{items[index]}</h1>
                  </animated.div>
                ))}

                <p className="header__content-links">
                  UX / Interface / Product Designer with 6 years of experience
                </p>
              </div>
              <div className="header__content-badges mb-5">
                <a
                  href="https://www.interaction-design.org/members/hasan-sharif/certificate/course/f7b88d6e-ed1e-40b3-9090-594c619dfef2"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light"
                >
                  {" "}
                  <img
                    src={ixdfbadge}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>

                <a
                  href="https://www.coursera.org/account/accomplishments/professional-cert/PMT5ZYFU3EFX"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={courserabadge}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>
                <a
                  href="https://adplist.org/mentors/hasan-sharif"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={adplist}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>
                <a
                  href="https://ux.stackexchange.com/users/142295/hasan"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={stackexchange}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>
                {/*<a
                  href="https://play.google.com/store/apps/details?id=com.sadaqahmadeeasy.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={playstorebadge}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>*/}
              </div>
            </div>

            <div className="header-btn-with-anim text-center">
              <a
                href="#works"
                className="btn btnCTA ramadan__project-btn border"
              >
                <i class="fa fa-bolt" aria-hidden="true"></i> &nbsp; See works
                in detail
              </a>

              <Link to="/animations" className="text-dark">
                <i class="fa fa-superpowers" aria-hidden="true"></i> &nbsp;
                Prototype fun works
              </Link>
            </div>
          </div>

          {/*<div className="col-md-5 col-12">
            <div className="header-image-card">
              <img src={headerselfimg} className="rounded" />{" "}
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
}

export default Header;
