/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React from "react";
import { useTrail, animated } from "@react-spring/web";
import { useLocation } from "react-router-dom";
import headerselfimg from "../img/header/headerselfimg.png";

function Intro() {
  const trails = useTrail(1, {
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200, // 2 second delay
    config: { tension: 280, friction: 60 },
  });

  return (
    <div className="container-fluid intro">
      {/*intro*/}
      <div className="row mt-4">
        <div className="col-md-8 col-12">
          <div className="glowable-text">
            <div className="card card-2 mb-3">
              <div className="card-body text-dark">
                {trails.map((props, index) => (
                  <animated.div key={index} style={props}>
                    <h4>
                      Hello there, I'm Hasan. I have worked with industries like
                      Intelligence Tool, SaaS, Education, Sustainable Energy,
                      E-commerce, Gamified Apps etc with a proven track record
                      of creating robust design systems for complex,
                      cross-platform solutions.
                    </h4>
                  </animated.div>
                ))}

                <a
                  href="#more"
                  className="btn btnCTA ramadan__project-btn border"
                >
                  <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>{" "}
                  &nbsp; More about me
                </a>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="col-md-4 col-12">
          <div className="header-image-card">
            <img src={headerselfimg} className="rounded" width={"auto"} />{" "}
          </div>
        </div>
        {/*intro*/}
      </div>
    </div>
  );
}

export default Intro;
